import React from 'react'

//import components
import Project from '../components/Project'
import Padding from '../components/Padding'
import FullWidthImage from '../components/FullWidthImage'
import Grid from '../components/Grid'
import NextSections from '../components/NextSections'

// import images
import fullImage1 from '../images/social-media-infographics/social-media-mockup-1.jpg'
import fullImage2 from '../images/social-media-infographics/social-media-mockup-2.jpg'
import gridImage1 from '../images/social-media-infographics/6_stats_FB_1.jpg'
import gridImage2 from '../images/social-media-infographics/FB_2.jpg'
import gridImage3 from '../images/social-media-infographics/FB_4.jpg'
import gridImage4 from '../images/social-media-infographics/FB_5.jpg'
import gridImage5 from '../images/social-media-infographics/FB_1.jpg'
import gridImage6 from '../images/social-media-infographics/6_stats_FB_2.jpg'

export default () => (
    <Project
        title={'social media graphics'}
            date={'2020'}
            position={'Graphic Designer'}
            employer={'Fast Capital 360'}
            brief={[
                'Fast Capital 360 is a financial company in the midst of a rebrand. Updates included a trendy new illustration style. Though these illustrations helped to create a more approachable feel for the brand, the financial purpose, and credibility of the company needed to remain. I was tasked to create a series of finance-related infographics that not only incorporated the playful illustrations but highlighted the valuable data being presented.',
                'Working with the Illustrator, Social Media Manager, and Art Director of the FC360 marketing department, I was able to create a series of visualizations that offered bite-sized facts relating to small businesses. The visuals were thoughtfully designed in a cohesive layout, within FC360’s style guidelines, to draw the eye of viewers as they scroll through their social feed. They were sized and posted to LinkedIn, Facebook, and Twitter.'
            ]}
            skills={[
                'Illustration & Layout Design',
                'Data Visualization',
                'Branding',
                'Social Media'
            ]}
    >
        <FullWidthImage src={fullImage1} alt={'person holding computer'} />
        <Padding />
        <Grid
            images={[
                    {
                        src: gridImage1,
                        alt: 'Icons repesenting aspects of periodic elements'
                    },
                    {
                        src: gridImage2,
                        alt: 'World map with shapes representing Production, Mine, and Production/Mine'
                    },
                    {
                        src: gridImage3,
                        alt: 'World map with shapes representing Production, Mine, and Production/Mine'
                    },
                    {
                        src: gridImage4,
                        alt: 'World map with shapes representing Production, Mine, and Production/Mine'
                    },
                    {
                        src: gridImage5,
                        alt: 'World map with shapes representing Production, Mine, and Production/Mine'
                    },
                    {
                        src: gridImage6,
                        alt: 'World map with shapes representing Production, Mine, and Production/Mine'
                    }
            ]}
            columns={2}
        />
        <Padding />
        <FullWidthImage src={fullImage2} alt={'person holding phone'} />
        <Padding />
        <NextSections
            previous={'make-me-chic-email'}
            next={'instagram-graphics'}
        />
    </Project>
)